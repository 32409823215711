import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Summary from 'content/blog/en/vercel-like-paas-summary';
export const _frontmatter = {
  "title": "Some reasons to build a PaaS with Kubernetes and GitOps",
  "subtitle": "Foreword",
  "cover": "k8s+gitlab.webp",
  "description": "This article is an introduction to the\n\"A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps\" series.\nBefore diving into technical details, I'd like to give some context\non why I've built this setup when managed solutions already exist.\n",
  "meta": {
    "title": "Some reasons to build a PaaS with Kubernetes and GitOps",
    "description": "Why Kubernetes and GitOps can be a alternative to fully managed PaaS"
  },
  "promoted": true,
  "publishedAt": "2022-02-11T12:00:00",
  "published": true,
  "tags": ["Kubernetes", "GitOps", "DevOps", "Automation"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`This article is an `}<strong parentName="p">{`introduction`}</strong>{` to the `}<em parentName="p">{`A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps`}</em>{` series.`}</p>
    <Summary mdxType="Summary" />
    <hr></hr>
    <p><em parentName="p">{`Before diving into technical details, I'd like to give some context. If you
want to go straight to the how-to guide, you can skip this introduction and jump
to `}<a parentName="em" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part1"
        }}>{`part I`}</a>{`.`}</em></p>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@1"
      }}>{`Starting with WHY`}</a></h2>
    <p>{`Over the last two years of remote work as a technical leader,
`}<a parentName="p" {...{
        "href": "https://miro.com/app/board/o9J_leBQdDQ=/"
      }}>{`I've established a software development life cycle`}</a>{`
that relies heavily on CI/CD automation.`}</p>
    <p>{`To make it easier for developers to share their work with other team members, I've
set up a workflow that allows any developer to deploy any commit or branch at will.`}</p>
    <p>{`Sharing development environments can be cumbersome. While `}<a parentName="p" {...{
        "href": "https://ngrok.com/"
      }}>{`tunneling`}</a>{`
traffic to a developer's machine can do the job, it doesn't fit well in an
asynchronous process`}<anote id="1" />{`.`}</p>
    <p>{`A simple solution to this problem is to deploy development environments on managed
platforms, as it often requires nearly zero knowledge and configuration.`}</p>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@2"
      }}>{`Accelerating software delivery at the development stage`}</a></h2>
    <p><a parentName="p" {...{
        "href": "https://vercel.com/"
      }}>{`Vercel`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.netlify.com/"
      }}>{`Netlify`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://pages.cloudflare.com/"
      }}>{`Cloudflare Pages`}</a>{` are popular managed hosting
platforms that automate deployments with a git-centric workflow. It's a simple
and easy-to-onboard user experience to solve a complex technical problem.`}</p>
    <p>{`Beyond simplicity, one feature I like from these platforms is how they keep
previous deployments always accessible from a `}<a parentName="p" {...{
        "href": "https://vercel.com/docs/concepts/deployments/automatic-urls"
      }}>{`unique URL`}</a>{`.
I also like how easy it is to promote any deployment to `}<a parentName="p" {...{
        "href": "https://vercel.com/docs/concepts/deployments/environments#production"
      }}>{`production`}</a>{`
or rollback to the previous deployment, with just two clicks.`}</p>
    <img alt="Vercel deployments page" caption="Vercel deployments interface" frame="box" src="vercel-deployments.webp" width={80} />
    <p>{`While it's a very simple way to deploy and rollback applications, these platforms
are limited to Javascript frontends and serverless-like functions as backends, also
known as the `}<a parentName="p" {...{
        "href": "https://jamstack.org/what-is-jamstack/"
      }}>{`Jamstack architecture`}</a>{`.`}</p>
    <p>{`They are not designed`}<anote id="2" />{` to host popular frameworks such as Rails,
Laravel, Django, NestJS, Phoenix, or platforms such as Wordpress or Drupal just
to name a few.`}</p>
    <p>{`Many Git-centric or `}<a parentName="p" {...{
        "href": "https://about.gitlab.com/topics/gitops/"
      }}>{`GitOps`}</a>{` oriented
platforms have been available long before Jamstack went mainstream. One can either
pick a specialized PaaS such as `}<a parentName="p" {...{
        "href": "https://pantheon.io/product/serverless-cms"
      }}>{`Pantheon`}</a>{`
for Drupal or Wordpress, `}<a parentName="p" {...{
        "href": "https://vapor.laravel.com"
      }}>{`Vapor`}</a>{` for Laravel, a less
specialized PaaS such as `}<a parentName="p" {...{
        "href": "https://platform.sh/marketplace/"
      }}>{`platform.sh`}</a>{`, or a more
general-purpose PaaS like `}<a parentName="p" {...{
        "href": "https://dashboard.heroku.com/apps"
      }}>{`Heroku`}</a>{`
where you can run Node.js, Ruby, Python, Java, PHP, Go, Scala and Clojure applications.`}</p>
    <p>{`But the simplicity of these platforms has a cost, and it grows rapidly.`}</p>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@2"
      }}>{`Looking for a better trade-off`}</a></h2>
    <p>{`Given the current state of the job market, it's often a better option to go with a
managed solution than hiring people to run and monitor production servers.`}</p>
    <p>{`Unfortunately, managed solution configurations are often limited to a short set of plans
such as "`}<em parentName="p">{`hobbyist, professional or agency`}</em>{`".`}</p>
    <p>{`It's part of what makes them profitable: they focus on a specific application hosting
market, with limited variations from the standard configuration, and target users who
have no business interests in learning to manage their own platform.`}</p>
    <p>{`For this customer segment, wasting money on unused resources is a better option than
the cost of operating a scalable production setup.`}</p>
    <p>{`So, how customers like development teams, who operate noncritical development environments,
can get simplicity without an expensive production grade level of service?`}</p>
    <p>{`How to cut down on resources waste, and avoid paying for features like high availability
infrastructure, high throughput, low latency network?`}</p>
    <p>{`What I'd like to show here is how to build a simple and cost efficient platform :`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Features`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Vercel`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Heroku`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`👋`}{` `}{`Here`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`One-click deployment`}<br /><small>{`From Web UI, GitOps workflow`}</small></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✓`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`One-click promote to production`}<br /><small>{`From Web UI`}</small></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✓`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Keep previous deployments accessible`}<br /><small>{`Unique https url`}</small></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✓`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✓`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Run any framework or language`}<br /><small>{`Including Rust, Elixir`}</small></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`✓`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@3"
      }}>{`What does the solution look like?`}</a></h2>
    <p>{`Over the course of the series, I'll build a solution with GitLab that consists of
three stages:`}</p>
    <ol>
      <li parentName="ol">{`Package the application in a Docker image.`}</li>
      <li parentName="ol">{`Deploy this image to a Kubernetes cluster and generate a unique URL to access
this application instance, such as `}<a parentName="li" {...{
          "href": "https://7c77eb36.nodejs.k0s.gaudi.sh"
        }}>{`https://7c77eb36.nodejs.k0s.gaudi.sh`}</a></li>
      <li parentName="ol">{`Promote the instance to production by pointing a non-prefixed URL to it, such
as `}<a parentName="li" {...{
          "href": "https://nodejs.k0s.gaudi.sh"
        }}>{`https://nodejs.k0s.gaudi.sh`}</a></li>
    </ol>
    <p>{`This is what it looks like in GitLab:`}</p>
    <img alt="gitlab pipeline interface" caption="A Vercel-like pipeline done with GitLab" frame="box" src="gitlab-k0s-pipeline.webp" width={80} />
    <p>{`Last lines of the `}<em parentName="p">{`deploy`}</em>{` job output show a unique URL to access the deployment:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "lines=5",
        "lines": "5"
      }}>{`deployment.apps/api configured
ingress.networking.k8s.io/api unchanged
service/api unchanged
Deployment succeeded.
URL: https://7c77eb36.nodejs.k0s.gaudi.sh/version
Cleaning up project directory and file based variables
Job succeeded
`}</code></pre>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "@4"
      }}>{`Start now and get it right later`}</a></h2>
    <p>{`Working with development environments is a low-risk strategy that allows
a development team to make some mistakes, learn the technology then shape a
process that fits their needs.`}</p>
    <p>{`Recently, `}<a parentName="p" {...{
        "href": "https://twitter.com/jeanqasaur"
      }}>{`Jean Yang`}</a>{` posted this article: `}<a parentName="p" {...{
        "href": "https://future.a16z.com/software-development-building-for-99-developers/?utm_source=pocket_mylist"
      }}>{`Building for the 99% Developers`}</a>{`,
exposing the hard reality a majority of developers faces:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“These are developers who are getting work done outside of the hip companies and
frameworks, who often get neglected in conversations about 'what developers want.'
There's a huge gap between what 'developer-influencers' are talking about,
and the daily reality of most developers.” `}<cite>{`Jean Yang, founder & CEO of Akita Software`}</cite></p>
    </blockquote>
    <p>{`As a regular listener and reader of tech news, I couldn't agree more with her,
as I really know the gap between what's been talking about out there and the reality of
development teams I've worked with.`}</p>
    <p>{`I often find myself helping developers who struggle with basic things such as
installing local development environments or misconfiguration. So, I'm aware that
throwing at them this trendy technology everybody talks about, `}<em parentName="p">{`Kubernetes`}</em>{`, doesn't
sound like I've understood the problem.`}</p>
    <h3><a parentName="h3" {...{
        "href": "@4-1"
      }}>{`The goal is progress, not perfection`}</a></h3>
    <p>{`In this series, I'm not describing what Jean Yang calls `}<em parentName="p">{`"an idealized process."`}</em></p>
    <p>{`While I've simplified things to focus on key concepts and to improve the narrativity,
this setup is part of a real process I've implemented in a development team I've
worked with for two years.`}</p>
    <p>{`It is definitely not perfect, whether because of my ignorance or because I've
intentionally left room for improvements.`}</p>
    <p>{`However, I think it gives a comprehensive starting point to anyone who's trying
to make sense of Docker, Kubernetes, CI/CD, GitOps and other trendy topics.`}</p>
    <p>{`The only fictional piece I've added is instructions to install a Kubernetes cluster with
k0s (1. and 2. of part I). I wanted to provide a cheap alternative to managed clusters
for people who might want to experiment with this workflow.`}</p>
    <p>{`The series starts here:`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "/en/blog/build-paas-kubernetes-gitops-part1"
        }}>{`A Vercel-like PaaS beyond Jamstack with Kubernetes and GitOps, part I: Cluster`}{` `}{`setup`}</a></strong></p>
    <hr></hr>
    <note id="1">
  Because the person who asked for access has to sync with a developer, set up a
  meeting, etc.
    </note>
    <note id="2">
  Back in 2018, when Vercel was still ZEIT, I successfully deployed Wordpress
  sites on this platform with Docker and Now.sh.
  <a href="https://vercel.com/support/articles/does-vercel-support-docker-deployments" target="_blank">
    Removing such capability
  </a> while pushing Next.js framework was a clear shift towards the Jamstack emmerging
  market.
    </note>
    <note id="4">
  I tried with a t2.micro with 1 GB of RAM which can be run for free as part of
  the{' '}
  <a href="https://aws.amazon.com/ec2/pricing/?loc=ft#Free_tier" target="_blank">
    AWS Free Tier offer
  </a>{' '}
  and fits the minimal system requirements of k0s for a controller+worker node,
  but it ended up being pretty unstable. I don't recommend it.
    </note>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      